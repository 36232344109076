@use "../config" as *;
html {
  &.no-scroll,
  &.no-scroll body {
    width: 100%;
    height: var(--window-inner-height);
    touch-action: none;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
    overflow: hidden;
  }
}
body {
  // padding-top: var(--main-header-height);
}
[v-cloak] {
  visibility: hidden;
}
ul {
  list-style: none;
}
.grecaptcha-badge {
  visibility: hidden;
}
.waf-row {
  clear: both;
  position: relative;
  &::before,
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}
.img-box {
  background-color: var(--shimmer-light-color);
  border-radius: var(--full-radius);
  overflow: hidden;
  img {
    height: auto;
    width: 100%;
  }
}
img {
  height: auto;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
}
.img-4by3 .img-box,
.img-16by9 .img-box,
.img-1by1 .img-box,
.img-2by3 .img-box,
.img-9by16 .img-box,
.img-21by9 .img-box,
.img-18by9 .img-box,
.img-18by6 .img-box,
.img-6by4 .img-box {
  position: relative;
  img {
    width: 100%;
    height: 100%;
    @include position(0, null, null, 0);
    &[alt] {
      font-size: 1.2rem;
      color: rgba(var(--body-text-color), 0.7);
    }
  }
}
.img-18by6 .img-box {
  padding-bottom: 33.33%;
}
.img-21by9 .img-box {
  padding-bottom: 42.85%;
}
.img-18by9 .img-box {
  padding-bottom: 50%;
}
.img-16by9 .img-box {
  padding-bottom: 56.25%;
}
.img-6by4 .img-box {
  padding-bottom: 66.66%;
}
.img-4by3 .img-box {
  padding-bottom: 75%;
}
.img-9by16 .img-box {
  padding-bottom: 177.77778%;
}
.img-2by3 .img-box {
  padding-bottom: 150%;
}
.img-1by1 .img-box {
  padding-bottom: 100%;
}
.waf-row {
  clear: both;
  position: relative;
}
.waf-row:before,
.waf-row:after {
  clear: both;
  content: "";
  display: table;
}
.waf-component {
  position: relative;
  display: block;
  padding: var(--full-space);
  margin: var(--half-space) 0;
}
.web {
  display: none;
}
.mob {
  display: block;
}
.swiper {
  overflow: hidden;
}
.flex20,
.flex25,
.flex30,
.flex33,
.flex40,
.flex50,
.flex60,
.flex70,
.flex80,
.flex100 {
  flex-basis: 100%;
  width: 100%;
}
.title {
  text-transform: capitalize;
}
.head-wrap {
  .title {
    text-transform: uppercase;
  }
}
.body-wrap {
  min-height: calc(var(--window-inner-height) - (var(--microsite-primary-header-height) + var(--secondary-header-height) + var(--footer-bottom-height) + var(--footer-middle-height) + var(--breadcrumb-height)));
}
.main-wrap {
  padding-top: var(--primary-header-height);
}
.scroll-top {
  display: none;
  position: fixed;
  right: 2rem;
  bottom: calc(2 * var(--full-space) + var(--footer-bottom-height));
  transition: 1s;
  z-index: var(--z-index8);
  &.active {
    display: block;
  }
  .btn-top-scroll {
    width: 4rem;
    height: 4rem;
    position: relative;
    background-color: var(--kabaddi-primary-color);
    clip-path: polygon(0% 0%, 100% 0, 100% 80%, 80% 100%, 0% 100%);
    @include border-radius(0);
    @include flex-config(flex, null, center, center);
    &::after {
      content: "\e82a";
      font: 1rem/1 $font-icon;
      color: var(--kabaddi-secondary-color);
      font-weight: 700;
      @include center(both);
    }
  }
}
.match-time {
  .graph-box {
    width: 3.4rem;
    height: 3.4rem;
    margin: 0 auto;
    border-radius: 50%;
    svg {
      width: 100%;
      height: 100%;
      overflow: visible;
    }
    .circle {
      &-inner {
        stroke: var(--grey-color);
        stroke-width: 1;
        fill: var(--white-color);
      }
      &-outer {
        animation: circle-chart-fill 2s reverse;
        transform: rotate(-90deg);
        transform-origin: center;
        stroke-linecap: round;
        stroke: var(--kabaddi-secondary-color);
        stroke-width: 2;
      }
    }
  }
}
.waf-ad {
  margin: 0 var(--half-space-negative);
  .waf-ad-wrapper {
    max-width: var(--container-max-width);
    height: 10rem;
    margin: 0 auto;
    background: var(--light-grey-color);
  }
}
.waf-select-box {
  position: relative;
  .selected-title {
    border: 0.15rem solid var(--kabaddi-secondary-color);
    width: 100%;
    height: 3.988rem;
    padding: 0 var(--full-space);
    position: relative;
    // @include border-radius(var(--half-radius));
    @include flex-config(flex, null, space-between, center);
    &::after {
      content: "\e809";
      font: 1.2rem/1 $font-icon;
      color: var(--kabaddi-accent-color);
      margin-left: var(--half-space);
      @include transition(500ms ease-in-out);
    }
    &[aria-expanded="true"] {
      ~ .select-box-wrap {
        display: block;
      }
      &::after {
        @include transform(rotate(180deg));
      }
    }
  }
  .select {
    &-box-wrap {
      display: none;
      width: 100%;
      z-index: var(--z-index10);
      @include position(3.988rem, 0, null, null);
    }
    &-list {
      background-color: var(--light-grey-color);
      @include border-radius(0 0 var(--half-radius) var(--half-radius));
      @include flex-config(flex, column, null, null);
    }
  }
  .list-item {
    // font: 1.2rem/1.4 $font-medium;
    padding: 1rem;
    color: var(--kabaddi-secondary-color);
    position: relative;
    &::after {
      content: "";
      width: 90%;
      height: 0.1rem;
      background-color: rgba(var(--kabaddi-secondary-color-rgb), 0.2);
      bottom: 0;
      @include center(horizontal);
    }
  }
  .dropdown-close {
    display: none;
  }
  .btn-text {
    color: var(--tertiary-color);
    font: 1.4rem/1.4 $font-bold;
    text-transform: capitalize;
  }
}
/* breadcrumb */
.home {
  .waf-breadcrumb {
    display: none;
  }
}
.shape {
  margin: 0;
  .waf-head {
    position: relative;
    margin: 0;
    height: var(--waf-head-height);
    @include flex-config(flex, null, null, center);
    .title {
      color: var(--white-color);
    }
    .head-wrap {
      height: 100%;
      align-items: center;
    }
    &::before {
      content: "";
      background: url("/static-assets/images/cssimages/head-bg-mob.png?v=#{$image-version}") no-repeat;
      position: absolute;
      width: var(--window-inner-width);
      height: calc(100% + 4rem);
      background-size: 100% 100%;
      top: -4rem;
      pointer-events: none;
      left: calc((var(--window-inner-width) - var(--container-max-width)) / 2 * -1);
    }
    &::after {
      content: "";
      background: var(--kabaddi-header-bg);
      height: 100%;
      position: absolute;
      top: 0;
      left: calc(calc(var(--window-inner-width) - var(--container-max-width)) / 2 * -1);
      width: var(--window-inner-width);
      z-index: -1;
      pointer-events: none;
    }
  }
}
.breadcrumb-wrap {
  position: relative;
  z-index: var(--z-index1);
}
.waf-breadcrumb {
  padding: 0 var(--half-space);
  margin: 0;
  color: var(--kabaddi-secondary-color);
  background: var(--light-grey-color);
  @include flex-config(flex, null, null, center);
  .breadcrumb {
    min-height: var(--breadcrumb-height);
    flex-wrap: wrap;
    padding: 0.5rem 0;
    @include flex-config(flex, null, null, center);
    &-item {
      list-style: none;
      position: relative;
      span {
        font: 1.1rem/1 $font-medium;
        color: (--black-color);
      }
      > span {
        color: (--black-color);
      }
      &:first-child {
        a {
          span {
            color: var(--kabaddi-accent-color);
            font-family: $font-bold;
          }
        }
      }
      &:last-child {
        word-break: break-all;
      }
      &:not(:last-child) {
        padding-right: var(--full-space);
        position: relative;
        &::after {
          content: "\e828";
          font: 0.6rem/1.2 $font-icon;
          right: 0.4rem;
          color: var(--black-color);
          @include position(45%, null, null, null);
        }
      }
    }
  }
}
//social-icons
.social {
  &-items {
    @include flex-config(flex, null, null, center);
  }
  &-icon {
    color: var(--white-color);
    @include flex-config(flex, null, null, null);
    &::before {
      font-size: 1.8rem;
      color: var(--white-color);
    }
    &:not(:last-child) {
      margin-right: var(--full-space);
    }
    .icon {
      font-size: 0;
    }
  }
}
.page-not-found {
  .waf-breadcrumb {
    display: none;
  }
}
/* filter css start */
.filter-section {
  height: var(--filter-height);
  background: var(--light-grey-color);
  margin: var(--full-space) 0;
  @include border-radius(0.5rem, unset);
  @include flex-config(flex, null, space-between, center);
  .filter {
    &-wrap {
      width: calc(100% - 6rem);
      height: 100%;
      @include flex-config(flex, null, null, center);
      .selected-title {
        padding: 0 var(--half-space);
        &::after {
          display: none;
        }
      }
      .waf-select-box {
        height: 100%;
        width: 20rem;
      }
    }
    &-actions {
      @include flex-config(flex, null, null, null);
      .btn {
        position: relative;
        &:not(:last-child) {
          &::after {
            content: "";
            background: rgb(var(--black-color-rgb), 0.7);
            height: 100%;
            width: 0.1rem;
            @include position(null, -1rem, null, null);
          }
        }
      }
    }
    &-head {
      .title {
        margin: 0;
        font: 1.4rem/1 $font-bold;
        color: var(--kabaddi-secondary-color);
      }
    }
  }
  .dropdown-close {
    display: none;
  }
  .selected-title {
    position: relative;
    cursor: pointer;
    padding: 1rem 4rem 1rem var(--full-space);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    font-family: $font-bold;
    border: unset;
    @include flex-config(flex, column, center, flex-start);
    .sub-title,
    .title {
      margin: 0;
      font-size: 1.4rem;
      line-height: 1.2;
      color: var(--kabaddi-secondary-color);
    }
    .sub-title {
      font: 1rem/1.2 $font-regular;
      color: rgb(var(--black-color-rgb), 0.5);
    }
    &::after {
      content: "\e827";
      font: 0.8rem/1 $font-icon;
      color: var(--kabaddi-secondary-color);
      @include position(null, 1.5rem, null, null);
    }
  }
  .select-box-wrap {
    text-align: center;
    background-color: var(--light-grey-color);
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 5rem 0 0.1rem 0;
    position: fixed;
    display: none;
    z-index: var(--select-box-z);
    bottom: 0;
    padding: 0;
    @include translate(0, 100%);
    @include transition(0.3s all);
    @include border-radius(0 0 var(--half-radius) var(--half-radius));
    @include box-shadow(0 0 0.5rem 0.2rem rgba(var(--black-color-rgb), 0.15));
  }
  .select-list {
    padding: 0 var(--half-space);
    overflow: auto;
    max-height: 30rem;
    overflow-x: hidden;
    .list-item {
      color: var(--kabaddi-secondary-color);
      padding: 1rem;
      position: relative;
      text-align: left;
      display: block;
      width: 100%;
      font: 1.4rem/1.2 $font-medium;
      height: 4rem;
      @include border-radius(var(--half-radius), unset);
      &::before {
        content: "";
        background: var(--kabaddi-secondary-color);
        height: 0.1rem;
        width: 100%;
        opacity: 0.1;
        @include position(null, null, 0, null);
      }
      &.active,
      &:focus,
      &:hover {
        background: var(--tertiary-color);
        color: var(--white-color);
      }
      a {
        color: inherit;
        font-size: inherit;
        display: block;
      }
    }
  }
  .btn-resetfilter {
    width: 3rem;
    &::after {
      content: "\e817";
      font-family: $font-icon;
      color: rgba(var(--black-color-rgb), 0.5);
      font-weight: 600;
    }
    .btn-text {
      color: var(--kabaddi-secondary-color);
      font: 0rem/1 $font-bold;
    }
  }
  .btn-more {
    position: relative;
    padding: 0 var(--half-space);
    color: var(--kabaddi-secondary-color);
    width: 3rem;
    @include flex-config(flex, null, center, null);
    &::after {
      display: none;
      content: "";
      height: 3rem;
      width: 0.1rem;
      background: var(--black-color);
      opacity: 0.2;
      right: var(--half-space-negative);
      @include center(vertical);
    }
    &::before {
      content: "\e866";
      font-family: $font-icon;
      color: rgba(var(--black-color-rgb), 0.5);
    }
    .btn-text {
      font-size: 0;
    }
  }
  .more-filters {
    position: fixed;
    height: calc(var(--window-inner-height) - (env(safe-area-inset-top) + env(safe-area-inset-bottom)));
    width: var(--window-inner-width);
    background: var(--white-color);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: var(--z-index10);
    @include flex-config(flex, column, null, null);
    display: none;
    .btn-cancel {
      color: var(--white-color);
      @include flex-config(flex, row-reverse, null, center);
      &::before {
        content: "\e817";
        font: 1.2rem/1 $font-icon;
        color: var(--kabaddi-primary-color);
      }
      .btn-text {
        padding-right: var(--half-space);
        color: var(--white-color);
      }
    }
    .head {
      height: 3rem;
      background: linear-gradient(0deg, rgba(var(--black-color-rgb), 0.2), rgba(var(--black-color-rgb), 0.2)),
        var(--kabaddi-secondary-color);
      padding: 0 var(--half-space);
      border-radius: 0 0 var(--half-radius) var(--half-radius);
      @include flex-config(flex, null, space-between, center);
      .title {
        font: 1.2rem/1.2 $font-medium;
        color: var(--white-color);
        margin: 0;
      }
    }
    .body {
      height: calc(100% - 9rem);
      overflow: auto;
      background-color: var(--white-color);
    }
    .footer {
      height: 6rem;
      @include flex-config(flex, null, center, center);
      @include box-shadow(0 0.1rem 1rem 0.1rem rgb(var(--black-color-rgb), 0.3));
      .btn-site {
        margin: 0 var(--half-space);
      }
      .btn-applyfilter,
      .btn-resetfilter {
        min-width: 14rem;
        font-family: $font-medium;
        height: 4rem;
        border-radius: var(--half-radius);
        @include flex-config(flex, null, center, center);
        .btn-text {
          font-size: 1.2rem;
        }
        &::before {
          font-family: $font-icon;
        }
      }
      .btn-resetfilter {
        color: rgba(var(--black-color-rgb), 0.7);
        margin-left: var(--full-space);
        border: 0.1rem solid rgb(var(--black-color-rgb), 0.2);
        .btn-text {
          order: -1;
          color: var(--black-color);
          padding-right: 1rem;
          color: rgba(var(--black-color-rgb), 0.7);
        }
      }
      .btn-applyfilter {
        background: var(--kabaddi-primary-color);
        .btn-text {
          color: var(--kabaddi-accent-color);
          font-family: $font-bold;
        }
      }
    }
    .selected-title {
      border-bottom: 0.1rem solid rgb(var(--black-color-rgb), 0.2);
      @include border-radius(0);
      .sub-title {
        font-size: 1.2rem;
      }
    }
    .select-list {
      list-style-type: none;
    }
    .select-box-wrap {
      position: relative;
      top: auto;
      bottom: unset;
      transform: unset;
      z-index: var(--z-index1);
      background: var(--white-color);
      box-shadow: none;
      width: 100%;
    }
  }
  .waf-select-box {
    position: relative;
    margin: 0;
    &.active {
      .selected-title {
        border-bottom: none;
      }
      .select-box-wrap {
        display: block;
      }
    }
  }
  &.active {
    .more-filters {
      display: block;
    }
  }
}
/* filter css end */
/* pagination css start */
.pagination {
  margin: var(--full-space) 0;
  @include flex-config(flex, null, center, center);
  a,
  span {
    font: 1.4rem/1.2 $font-medium;
    color: var(--kabaddi-accent-color);
  }
  .disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  a,
  .pagination-dots {
    width: 2.8rem;
    height: 2.8rem;
    position: relative;
    border: 0.1rem solid transparent;
    margin: 0 calc(var(--half-space) / 3);
    @include border-radius(var(--half-radius));
    @include flex-config(inline-flex, null, center, center);
    &.active,
    &:focus,
    &:hover {
      background-color: var(--kabaddi-accent-color);
      color: var(--white-color);
      font-family: $font-bold;
      span {
        color: var(--kabaddi-secondary-color);
      }
    }
  }
  .nav-first,
  .nav-last {
    display: none;
  }
  .nav-arrow {
    cursor: pointer;
    color: var(--kabaddi-secondary-color);
    border: 0.1rem solid transparent;
    background: linear-gradient(180deg, var(--kabaddi-light-gradient) 35.29%, var(--kabaddi-dark-gradient) 100%);
    &::before {
      content: "";
      font: 1.4rem/1 $font-icon;
      position: absolute;
    }
    // &:hover,
    // &:focus {
    //     background-color: var(--kabaddi-accent-color);
    //     border: none;
    // }
    span {
      font-size: 0;
      color: inherit;
    }
    &.nav-left {
      margin-right: var(--half-space);
      &::before {
        content: "\e829";
      }
      span {
        padding-left: var(--full-space);
      }
    }
    &.nav-right {
      margin-left: var(--half-space);
      &::before {
        content: "\e828";
      }
      span {
        padding-right: var(--full-space);
      }
    }
    &.disabled {
      color: var(--kabaddi-secondary-color);
      background: transparent;
      border-color: var(--kabaddi-secondary-color);
      span {
        color: inherit;
      }
    }
  }
}
/* pagination css end */
.loadmore {
  color: var(--white-color);
  width: 14rem;
  height: 3rem;
  padding-right: 1.5rem;
  position: relative;
  border: 0.1rem solid rgba(var(--kabaddi-primary-color-rgb), 0.2);
  color: var(--white-color);
  font: 1.4rem/1.2 $font-medium;
  margin: var(--full-space) 0;
  background-color: var(--kabaddi-accent-color);
  @include border-radius(var(--half-radius));
  @include flex-config(flex, null, center, center);
  &-wrap {
    @include flex-config(flex, null, center, null);
  }
  &::after {
    position: relative;
    content: "\e827";
    font: 0.7rem/1 $font-icon;
    color: var(--kabaddi-secondary-color);
    transition: 0.2s;
    margin: 0.2rem 0 0 0.5rem;
    // @include position(null, 3rem, null, null);
  }
  &[aria-expanded="true"] {
    &::after {
      transform: rotate(180deg);
    }
  }
}
/*share css */
.reaction-section {
  .share-label {
    display: none;
  }
  .icon-b-share {
    position: relative;
    font-size: 0;
    &::after {
      content: "\e868";
      color: var(--kabaddi-secondary-color);
      font: 1.6rem/1.2 $font-icon;
    }
  }
  .social-share-wrap {
    display: none;
    transform: translateY(150%);
    transition: 1s ease-in;
  }
  .social-wrap {
    @include flex-config(flex, null, null, null);
  }
  .icon-facebook {
    background: var(--facebook-color);
  }
  .icon-twitter {
    background: var(--twitter-color);
  }
  .icon-whatsapp {
    background: var(--whatsapp-color);
  }
  .icon-instagram {
    background: var(--instagram-color);
  }
  .icon-copy-link {
    background: var(--copylink-color);
  }
  &.active {
    .social-share-wrap {
      display: block;
      transform: translateY(0%);
      background: rgba(var(--black-color-rgb), 0.7);
      position: fixed;
      bottom: 0;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: var(--header-z);
      @include flex-config(flex, null, null, flex-end);
      .social-wrap {
        background: var(--white-color);
        width: var(--window-inner-width);
        padding: var(--full-space);
        transition: 0.5s ease-in-out;
        @include border-radius(var(--full-radius) var(--full-radius) 0 0);
      }
      .share-label {
        display: block;
        padding-bottom: var(--full-space);
        margin-bottom: var(--full-space);
        height: 4rem;
        font: 1.5rem/1.2 $font-bold;
        color: var(--kabaddi-secondary-color);
        text-transform: capitalize;
        text-align: center;
      }
      .social-items {
        overflow-x: auto;
        margin-bottom: calc(var(--full-space) * 2);
        @include flex-config(flex, null, space-evenly, center);
      }
      .social-icon {
        margin: 0;
        font-size: 2rem;
        color: var(--white-color);
        width: 4rem;
        height: 4rem;
        position: relative;
        border: 0.1rem solid rgba(var(--black-color-rgb), 0.3);
        @include border-radius(var(--full-radius));
        @include flex-config(flex, null, center, center);
        i {
          width: 100%;
          height: 100%;
          &::before {
            color: var(--white-color);
            font-size: 2.2rem;
            line-height: 3.4rem;
          }
        }
      }
      .close {
        background-color: var(--white-color);
        color: var(--kabaddi-accent-color);
        width: 100%;
        height: 3.7rem;
        border: 0.1rem solid var(--kabaddi-primary-color);
        @include border-radius(var(--full-radius));
        i {
          font: 1.4rem/1.2 $font-bold;
          color: var(--kabaddi-secondary-color);
          &::before {
            margin-right: var(--half-space);
          }
        }
      }
    }
  }
}
.waf-head {
  margin-bottom: var(--full-space);
  .title {
    font-size: 2rem;
    color: var(--kabaddi-secondary-color);
    font-family: $font-bold;
  }
  .head-wrap {
    @include flex-config(flex, null, space-between, null);
  }
  .head-tab {
    margin: 0 var(--half-space-negative);
    @include flex-config(flex, null, null, center);
    li {
      position: relative;
      // &:not(:last-child) {
      //   &:after {
      //     content: "";
      //     height: 1.2rem;
      //     width: 0.1rem;
      //     background: var(--kabaddi-accent-color);
      //     position: absolute;
      //     transform: translateY(-50%);
      //     top: 50%;
      //   }
      // }
      a {
        font-family: $font-medium;
        font-size: 1.2rem;
        color: var(--kabaddi-accent-color);
        height: 2.4rem;
        padding: 0 var(--half-space);
        margin: 0 0.5rem;
        text-decoration: none;
        // text-decoration: revert;
        background: var(--kabaddi-primary-color);
        line-height: 1;
        @include flex-config(inline-flex, null, center, center);
        @include border-radius(0.3rem);
      }
    }
  }
}
// .breadcrumb-fluid {
//     .head-pattern .waf-head {
//         &::after {
//             left: var(--full-space-negative);
//         }
//         &::before {
//             right: var(--full-space-negative);
//         }
//         .head-wrap{
//             width: var(--container-max-width);
//             margin: 0 auto;
//         }
//     }
// }
.live-tab-containe {
  .no-data-text,
  .no-data-found {
    background: var(--black-color);
    color: var(--white-color);
  }
}
.no-data-text,
.no-data-found,
.no-data,
.nomatch-section {
  background: var(--light-grey-color);
  text-align: center;
  max-width: var(--container-max-width);
  margin: auto;
  padding: var(--full-space);
  border-radius: var(--half-radius);
  font: 1.4rem/1 $font-bold;
}
.first-list {
  .article-item {
    .item-type-icon {
      width: 3.4rem;
      height: 3.4rem;
      &::after {
        font-size: 1.4rem;
      }
    }
  }
}
.article {
  &-meta {
    .meta {
      font-size: 1.2rem;
      color: rgb(var(--black-color-rgb), 0.5);
    }
  }
  &-wrap {
    position: relative;
  }
  &-thumbnail {
    position: relative;
    @include thumb-clip(88%, 94%);
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg,
          var(--fourth-color) 7.89%,
          rgba(var(--kabaddi-secondary-color-rgb), 0.54) 43.49%,
          rgba(var(--kabaddi-secondary-color-rgb), 0) 75.42%);
      pointer-events: none;
      @include position(null, null, 0, null);
    }
  }
  &-item {
    position: relative;
    .img-box {
      @include border-radius(0 0 var(--half-radius) var(--half-radius));
      img {
        transform: scale(1.025) rotate(0.02deg);
        transition: transform 3s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
    &:hover,
    &:focus {
      .img-box {
        img {
          transform: scale(1.125) rotate(0.02deg);
        }
      }
      .article-thumbnail {
        &::before {
          width: 100%;
        }
      }
    }
    .item-type-icon {
      position: absolute;
      bottom: 0;
      left: var(--full-space);
      width: 2.4rem;
      height: 2.4rem;
      z-index: var(--item-type-z);
      background: linear-gradient(180deg, var(--kabaddi-light-gradient) 35.29%, var(--kabaddi-dark-gradient) 100%);
      @include flex-config(flex, null, center, center);
      @include icon-clip();
      &::after {
        content: "";
        font-family: $font-icon;
        font-size: 1rem;
        color: var(--kabaddi-secondary-color);
      }
    }
    &.item-type-video {
      .item-type-icon {
        &::after {
          content: "\e826";
        }
      }
    }
    &.item-type-article {
      .item-type-icon {
        &::after {
          content: "\e821";
        }
      }
    }
    &.item-type-photo {
      .item-type-icon {
        &::after {
          content: "\e822";
        }
      }
    }
  }
}
.third-list {
  .item-type-icon {
    width: auto;
    height: auto;
    background: unset;
    &::after {
      color: var(--kabaddi-primary-color);
      font-size: 1.8rem;
    }
  }
  .item-type-video {
    .item-type-icon {
      &::after {
        content: "\e803";
      }
    }
  }
}
.btn-site,
.btn-outline {
  width: 20rem;
  height: 4rem;
  margin: 0 auto;
  color: var(--white-color);
  background-color: var(--kabaddi-accent-color);
  font-family: $font-medium;
  font-size: 1.2rem;
  border-radius: var(--half-radius);
}
.bg-1 {
  &::before {
    content: "";
    background: url("/static-assets/images/cssimages/khokho-mweb-bg.png?v=#{$image-version}") no-repeat;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    pointer-events: none;
    @include position(0, null, null, 0);
  }
}
.waf-component {
  &.min-height {
    min-height: calc(var(--window-inner-height) - (var(--primary-header-height) + var(--breadcrumb-height) + var(--main-footer-height)));
  }
}
.secondary-menu {
  .waf-component {
    &.min-height {
      min-height: calc(var(--window-inner-height) - (var(--primary-header-height) + var(--microsite-primary-header-height) + var(--breadcrumb-height) + var(--main-footer-height)));
    }
  }
}
.breadcrumb-white {
  .waf-breadcrumb {
    background-color: var(--light-grey-color);
    .breadcrumb-item {
      span {
        color: var(--black-color);
      }
      > span {
        color: var(--black-color);
      }
      &:first-child {
        a {
          span {
            font-family: $font-bold;
            color: var(--kabaddi-accent-color);
          }
        }
      }
      &:last-child {
        word-break: break-all;
      }
      &:not(:last-child) {
        &::after {
          color: var(--black-color);
        }
      }
    }
  }
}
.tab {
  &s {
    overflow: hidden;
    border-bottom: 0.2rem solid rgba(var(--black-color-rgb), 0.1);
    @include flex-config(flex, null, null, center);
    @include border-radius(0.3rem);
    &.secondary-tab {
      .tab {
        &-anchor {
          background: var(--white-color);
        }
        &-name {
          height: auto;
          padding: var(--half-space);
          color: var(--kabaddi-secondary-color);
          position: relative;
          &.active,
          &.tab-active {
            &::before {
              background-color: var(--kabaddi-primary-color);
            }
            .tab-anchor {
              background: transparent;
              color: var(--tabs-color);
            }
          }
        }
      }
    }
  }
  &-name {
    width: calc(100% / 3);
    height: 4rem;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    @include flex-config(flex, null, center, center);
    &::before {
      content: "";
      background-color: var(--grey-color);
      height: 0;
      width: 100%;
      position: absolute;
      bottom: 0;
    }
    &:hover,
    &.active,
    &.tab-active {
      font-family: $font-bold;
      color: var(--kabaddi-accent-color);
      &::before {
        height: 0.2rem;
        background-color: var(--kabaddi-accent-color);
      }
    }
    &:not(:last-child) {
      &::after {
        content: "";
        background-color: var(--black-color);
        height: 80%;
        width: 0.1rem;
        position: absolute;
        right: 0;
        opacity: 0.1;
        @include center(vertical);
      }
    }
    .tab-anchor {
      background-color: var(--grey-color);
      color: var(--kabaddi-secondary-color);
      text-decoration: none;
      transform: none;
      border-radius: 0;
      border: none;
      height: 100%;
      width: 100%;
      font-family: $font-medium;
      @include border-radius(0.5rem);
      @include flex-config(flex, null, center, center);
      &:after {
        display: none;
      }
    }
    &.active {
      .tab-anchor {
        background-color: var(--tertiary-color);
        font-family: $font-bold;
        color: var(--white-color);
      }
    }
  }
}
.wpl-page {
  &.secondary-menu {
    .widget-layout-04 {
      .preview-swiper {
        height: 100%;
      }
      .article {
        &-thumbnail {
          &::after {
            display: none;
          }
        }
        &-item {
          &:hover {
            .img-box img {
              transform: unset;
            }
          }
        }
      }
    }
  }
}
.waf-showcase {
  &.womens-league {
    .article-thumbnail {
      a {
        cursor: initial;
      }
      &::after {
        display: block;
        // display: none;
      }
    }
  }
}
.hide-sponsors {
  .footer-top-section {
    @extend %d-none;
  }
}
@media screen and (min-width: $tablet-min-width) {
  .scorestrip-toggle {
    padding-bottom: var(--scorestrip-height);
  }
  .shape {
    .waf-head {
      &::before {
        background: url("/static-assets/images/cssimages/head-bg-web.png?v=#{$image-version}") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .bg-1 {
    &::before {
      content: "";
      background: url(/static-assets/images/cssimages/khokho-web-bg.png?v=1.0) no-repeat;
      background-size: 100% 100%;
    }
  }
  .listing-page {
    .item-type-icon {
      width: 3.4rem;
      height: 3.4rem;
      &::after {
        font-size: 1.6rem;
      }
    }
  }
  .mob {
    display: none;
  }
  .web {
    display: block;
  }
  .flex20 {
    flex-basis: 20%;
    width: 20%;
  }
  .flex25 {
    flex-basis: 25%;
    width: 25%;
  }
  .flex30 {
    flex-basis: 30%;
    width: 30%;
  }
  .flex33 {
    flex-basis: 33.33%;
    width: 33.33%;
  }
  .flex40 {
    flex-basis: 40%;
    width: 40%;
  }
  .flex50 {
    flex-basis: 50%;
    width: 50%;
  }
  .flex60 {
    flex-basis: 60%;
    width: 60%;
  }
  .flex70 {
    flex-basis: 70%;
    width: 70%;
  }
  .flex80 {
    flex-basis: 80%;
    width: 80%;
  }
  .flex100 {
    flex-basis: 100%;
    width: 100%;
  }
  .waf-error-page {
    .error-page-wrap {
      flex-wrap: nowrap;
    }
    .error-page-image,
    .error-page-content {
      flex-basis: 40%;
      width: 40%;
    }
    .error-page-content {
      text-align: left;
    }
    .title {
      font-size: 3rem;
    }
  }
  .waf-head {
    .title {
      font-size: 2.2rem;
    }
    .head-wrap {
      @include flex-config(null, row, space-between, null);
    }
  }
  /* filter css start */
  .filter-section {
    padding: 0;
    height: 5rem;
    .select-list {
      padding: 0;
    }
    .select-box-wrap {
      width: 20rem;
      transform: unset;
      max-height: 30rem;
      overflow: auto;
      overflow-x: hidden;
      @include position(5rem, null, unset, null);
    }
    .filter-wrap {
      width: calc(100% - 18rem);
      .waf-select-box {
        margin: 0 calc(var(--half-space) / 2);
        &:after {
          content: "";
          background: rgba(var(--black-color-rgb), 0.1);
          height: 80%;
          width: 0.1rem;
          right: 0;
          @include center(vertical);
        }
      }
      .selected-title {
        &::after {
          display: block;
        }
      }
    }
    .btn-resetfilter {
      width: 15rem;
      .btn-text {
        font-size: 1.2rem;
        order: -1;
        padding: 0 var(--half-space);
      }
    }
    .btn-more {
      &::after {
        display: block;
      }
    }
    .more-filters {
      width: 50rem;
      right: 0;
      left: unset;
      height: calc(100% - var(--main-header-height));
      bottom: 0;
      top: unset;
      @include box-shadow(0 0.1rem 1rem 0.1rem rgb(var(--black-color-rgb), 0.3));
    }
  }
  /* filter css end */
  /* pagination css start */
  .pagination {
    .nav-arrow {
      width: 10rem;
      span {
        font-size: 1.2rem;
      }
      &::before {
        position: relative;
        font-size: 1.2rem;
      }
      &.nav-left {
        span {
          padding-left: var(--full-space);
        }
      }
      &.nav-right {
        span {
          order: -1;
        }
      }
    }
    &-wrap {
      margin-top: calc(var(--full-space) * 2);
    }
  }
  /* pagination css end */
  .waf-breadcrumb {
    .layout-wrapper {
      width: var(--container-max-width);
      margin: 0 auto;
      // padding: 0 var(--half-space);
    }
  }
  .views-section {
    display: none;
  }
  /*share css start*/
  .reaction-section {
    .share-icon {
      display: none;
    }
    .social-share-wrap {
      display: block;
      transform: none;
    }
    .icon {
      &-facebook {
        &::before {
          color: var(--kabaddi-secondary-color);
        }
      }
      &-twitter {
        &::before {
          color: var(--kabaddi-secondary-color);
        }
      }
      &-whatsapp {
        &::before {
          color: var(--kabaddi-secondary-color);
        }
      }
      &-instagram {
        &::before {
          color: var(--kabaddi-secondary-color);
        }
      }
      &-copylink {
        &::before {
          color: var(--kabaddi-secondary-color);
        }
      }
      &-facebook,
      &-twitter,
      &-whatsapp,
      &-instagram,
      &-copy-link {
        background: transparent;
      }
    }
  }
  /*share css end*/
  .swiper-button {
    &-disabled {
      background-color: transparent;
      border: 0.1rem solid var(--kabaddi-secondary-color);
    }
    &-next,
    &-prev {
      width: 2.8rem;
      height: 2.8rem;
      border: 0.1rem solid var(--kabaddi-secondary-color);
      background-color: var(--kabaddi-secondary-color);
      position: relative;
      z-index: var(--z-index10);
      @include border-radius(var(--half-radius));
      &::after {
        font-size: 1.4rem;
        font-family: $font-icon;
        color: var(--white-color);
        @include center(both);
      }
    }
    &-next {
      &::after {
        content: "\e819";
      }
    }
    &-prev {
      &::after {
        content: "\e818";
      }
    }
  }
  .custom-page {
    .waf-breadcrumb {
      padding-top: var(--full-space);
    }
  }
}
//  ERROR PAGE   //
.waf-error {
  background: rgba(var(--light-grey-color-rgb), 0.5) url("/static-assets/images/cssimages/error-bg.png?v=1.1") no-repeat;
  margin: calc(2 * var(--full-space)) var(--full-space);
  padding: calc(8 * var(--full-space)) var(--full-space);
  border-radius: var(--half-radius);
  mix-blend-mode: multiply;
  .error-body {
    flex-wrap: wrap;
    width: 100%;
    @include flex-config(flex, column, space-between, center);
    .error-img {
      width: 26rem;
      height: 15rem;
      max-width: 100%;
    }
    .error-text {
      text-align: center;
      .text {
        &-head {
          font-size: 2.5rem;
          line-height: 1.4;
          color: var(--kabaddi-secondary-color);
          font-family: $font-bold;
        }
        &-description {
          font-size: 1.5rem;
          line-height: 1.4;
          color: rgba(var(--kabaddi-secondary-color-rgb), 0.5);
          margin-bottom: var(--full-space);
          font-family: $font-medium;
        }
        &-link {
          font-size: 1.6rem;
          line-height: 1.4;
          text-decoration: underline;
          font-family: $font-regular;
          color: var(--kabaddi-primary-color);
        }
      }
    }
  }
}
@media screen and (min-width: $tablet-min-width) {
  .waf-error {
    padding: calc(var(--full-space) * 6);
    margin-top: calc(4 * var(--full-space));
    .error-body {
      flex-wrap: nowrap;
      @include flex-config(flex, column, space-between, center);
      .error-img {
        width: 36rem;
        height: 20rem;
        max-width: 100%;
      }
      .error-text {
        text-align: center;
        margin-left: calc(var(--full-space) * 3);
      }
    }
  }
}
@media screen and (min-width: $desktop-min-width) {
  .waf-error {
    background: rgba(var(--light-grey-color-rgb), 0.5) url("/static-assets/images/cssimages/error-bg-web.png?v=1.1") no-repeat;
    height: 43rem;
    .error-body {
      @include flex-config(null, row, null, null);
      .error-text {
        text-align: left;
        .text-head {
          font-size: 4rem;
          line-height: 1.4;
          margin-bottom: var(--full-space);
          font-family: $font-bold;
        }
      }
    }
  }
}