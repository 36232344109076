@use "../config" as *;

.home {
	.body-wrap {
		min-height: calc(var(--window-inner-height) - (var(--primary-header-height) + var(--footer-bottom-height) + var(--footer-middle-height)));
	}

	.menu-wrap {
		background-color: var(--secondary-color);

		.site-header {

			//height: 6.5rem;
			&:after {
				@include linear-gradient(0deg, var(--secondary-color) 100%, var(--secondary-color) 100%);

			}
		}
	}

	.btn-site-hamburger {
		.line {
			background-color: var(--accent-color);
		}
	}

}

.wpl-page,
.wpl-logo {
	.site {
		&-header {
			.logo {
				width: 9rem;

				&::before {
					background: url(/static-assets/images/cssimages/svg/wpl-logo.svg?v=#{$image-version}) center center no-repeat;
					background-size: contain;
				}
			}
		}
	}

	.secondary-site-header {
		.logo {
			width: auto;
			height: auto;
			white-space: nowrap;
		}
	}
}

.llc-page {

	.secondary-site,
	.site {
		&-header {
			.logo {
				height: 4.5rem;

				&::before {
					background: url(/static-assets/images/cssimages/svg/llc-logo.svg?v=#{$image-version}) center center no-repeat;
					background-size: contain;
				}
			}
		}
	}
}

.secondary-site,
.site {
	&-header {
		padding: 0 var(--full-space);
		height: 100%;

		@include flex-config(flex, null, space-between, center);

		.logo {
			position: relative;
			width: var(--logo-width);
			height: var(--logo-height);
			@include flex-config(flex, null, null, null);

			&::before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				background: url(/static-assets/images/cssimages/svg/site-logo.svg?v=#{$image-version}) center center no-repeat;
				background-size: contain;
			}

			&-text {
				font-size: 0;
			}
		}

		.submenu {
			padding-bottom: 0;

			.sub-ul {
				display: none;
				margin-left: var(--full-space);

				.nav-anchor,
				.btn-nav-anchor {
					padding: var(--half-space) var(--full-space);
				}
			}
		}

		.social-wrap {
			display: block;
			border-top: 0.1rem solid var(--light-grey-color);
		}
	}

	&-nav {

		.nav-anchor,
		.btn-nav-anchor {
			font-size: 1.4rem;
			color: var(--kabaddi-secondary-color);
			height: 100%;
			width: 100%;
			position: relative;
			text-align: left;
			display: block;
			cursor: pointer;
			padding: var(--full-space);
			font-family: $font-medium;

			>span {
				font-size: inherit;
				color: inherit;
			}
		}

		.btn-nav-anchor {
			padding-right: calc(var(--full-space) * 2);

			&:after {
				content: "\e827";
				font-family: $font-icon;
				position: absolute;
				right: var(--full-space);
				font-size: 0.6rem;
				transform: translateY(-50%);
				top: 50%;
				@include transition(0.3s all);
			}

			&[aria-expanded="true"] {
				&:after {
					transform: rotate(-180deg);
				}

				~.sub-ul {
					display: block;
					padding-bottom: var(--half-space);
				}
			}
		}

		.navigation-link {
			position: relative;
			white-space: nowrap;

			&.active {

				>.nav-anchor,
				>.btn-nav-anchor {
					font-family: $font-bold;
				}
			}
		}
	}
}

.menu-wrap {
	background-color: var(--secondary-color);
	position: fixed;
	width: 100%;
	top: 0;
	z-index: var(--z-index10);

	.social-wrap {
		.social {
			&-item {
				color: var(--kabaddi-header-bg);
			}

			&-link-list {
				height: var(--social-link-height);
				@include flex-config(null, null, flex-start, null);
			}
		}
	}

	.site-nav {
		position: fixed;
		width: 100%;
		height: calc(var(--window-inner-height) - var(--primary-header-height));
		left: 0;
		transform: translateX(200%);
		top: var(--primary-header-height);
		z-index: var(--site-nav-z);
		background-color: var(--white-color);
		@include transition(all 1s ease-in-out);

		.navigation-link {
			position: relative;
			white-space: initial;

			&::after {
				content: "";
				background-color: var(--light-grey-color);
				height: 0.1rem;
				width: calc(100% - var(--full-space));
				margin: 0 auto;
				@include position(null, 0, null, 0);
			}

			&.active {

				>.nav-anchor,
				>.btn-nav-anchor {
					font-family: $font-bold;
					background-color: var(--kabaddi-primary-color);
				}

				// .nav-anchor,
				// .btn-nav-anchor {
				// 	font-weight: $font-bold;
				// 	color: var(--kabaddi-primary-color);
				// }
			}

			&-list {
				border-top: 0.1rem solid var(--light-grey-color);
				overflow: auto;
				height: calc(100% - var(--social-link-height));
			}

			// .nav-anchor,
			// .btn-nav-anchor {
			// 	&::before {
			// 		content: "";
			// 		font-family: $font-icon;
			// 	}
			// 	&.home {
			// 		&::before {
			// 			content: "\e82c";
			// 		}
			// 	}
			// 	&.kabaddi {
			// 		&::before {
			// 			content: "\e825";
			// 		}
			// 	}
			// }
		}

		.sub-ul {
			.navigation-link {

				&.active,
				&:hover {
					>.nav-anchor {
						background: var(--kabaddi-primary-color);
						color: var(--kabaddi-accent-color);
					}
				}

				&:last-child {
					&::after {
						display: none;
					}
				}
			}
		}
	}

	.site-header {
		height: var(--primary-header-height);

		&:after {
			content: "";
			height: 0.3rem;
			width: 100%;
			@include position(null, null, 0, 0);
			@include linear-gradient(270deg, var(--kabaddi-accent-color) 63.21%, var(--kabaddi-primary-color) 95.83%);
		}
	}

	.partner-wrap {
		position: absolute;
		right: 60px;

		.partner-item {
			height: 3.5rem;
			display: block;
			cursor: default;
		}

		.partner-img {
			height: 100%;
			width: auto;
			object-fit: contain;
		}
	}
}

.secondary {
	&-menu-wrap {
		height: var(--secondary-header-height);
		background-color: var(--accent-color);
		position: fixed;
		width: 100%;
		left: 0;
		top: var(--primary-header-height);
		z-index: var(--secondary-menu);
		@include transition(0.3s all);
	}

	&-site-header {
		margin: 0 var(--full-space-negative);

		.secondary-site-logo {
			width: auto;
			margin: 0;
		}

		.logo {
			width: auto;
			height: auto;
			white-space: nowrap;

			&::before {
				display: none;
			}

			&-text {
				font-size: 1.8rem;
				text-transform: uppercase;
				font-family: $font-bold;
				display: none;
			}
		}

		.submenu {
			.sub-ul {
				position: fixed;
				z-index: var(--z-index9);
				bottom: 0;
				width: 100%;
				left: 0;
				margin: 0;
				background-color: var(--white-color);
				transform: translateY(100%);
				@include transition(0.3s all);
				@include flex-config(none, column, null, null);

				// .nav-link {
				// 	padding: var(--half-space) 0;
				// }
			}
		}
	}

	&-site-nav {
		width: 100%;

		.navigation-link {

			&.active,
			&:hover {

				>.nav-anchor,
				>.btn-nav-anchor {
					color: var(--kabaddi-accent-color);
				}
			}

			&-list {
				width: 100%;
				overflow: scroll;
				overflow-y: hidden;
				@include flex-config(flex, null, null, center);
			}
		}

		.btn-nav-anchor[aria-expanded="true"] {
			~.sub-ul {
				transform: translateY(0);
			}
		}
	}
}

.btn-site-hamburger {
	position: relative;
	cursor: pointer;
	@include flex-config(flex, column, null, center);

	&[aria-expanded="true"] {
		~.site-nav {
			transform: translateY(0);
			@include transition(all 1s ease-in-out);
		}

		.line {
			&:first-child {
				transform: translateY(1.1rem) rotate(-45deg);
			}

			&:nth-child(2) {
				height: 0;
			}

			&:last-child {
				transform: translateY(-0.6rem) rotate(45deg);
			}
		}
	}

	.line {
		width: 3rem;
		height: 0.3rem;
		background-color: var(--kabaddi-primary-color);
		transition-property: transform, width, justify-content;
		@include transition(500ms ease-in-out);

		&:nth-child(2) {
			margin: var(--half-space) 0;
		}
	}
}

.social-wrap {
	.social {
		&-text {
			font-size: 0;

			&::before {
				font-size: 1.4rem;
			}
		}

		&-item {
			margin: 0 var(--half-space);
			color: var(--white-space);
		}

		&-link-list {
			@include flex-config(flex, null, center, center);

			.link-item {
				color: var(--white-color);
				padding: 0 var(--half-space);
			}
		}
	}
}

/*if secondary menu visible*/
.secondary-menu {
	// .menu-wrap {
	// 	height: var(--microsite-primary-header-height);
	// }

	.main-wrap {
		padding-top: calc(var(--microsite-primary-header-height) + var(--secondary-header-height));
	}

	// .site-header {
	// 	.site-logo {
	// 		margin-top: 3rem;
	// 	}

	// 	// .logo {
	// 	// 	width: 6rem;
	// 	// }
	// }
}

.sticky-menu {
	.secondary-menu-wrap {
		top: 0;
		z-index: var(--z-index10);
	}
}

@media screen and (min-width: $tablet-min-width) {
	.secondary-menu {
		.menu-wrap {
			.site-header {
				height: var(--microsite-primary-header-height);
			}

			.partner-wrap {
				.partner-item {
					height: 3.5rem;
				}
			}
		}

		.site-header {
			.site-logo {
				margin-top: 3rem;
			}

			.logo {
				width: 6rem;
				height: 6.4rem;
			}

			.secondary-site-header {
				.logo {
					width: auto;
					height: auto;
				}
			}
		}

		.site-nav {
			.navigation-link {
				.sub-ul {
					top: 4rem;
				}
			}
		}

		&.wpl-page {
			.site-header {
				.site-logo {
					margin-top: 0;
				}

				.logo {
					width: 9rem;
				}
			}
		}
	}

	.secondary {
		&-menu-wrap {
			top: var(--microsite-primary-header-height);
		}
	}

	.btn-site-hamburger {
		display: none;
	}

	.menu-wrap {
		.site {
			&-nav {
				background-color: transparent;
				width: calc(var(--window-inner-width) - 10rem);
				position: relative;
				transform: unset;
				top: unset;
				left: unset;
				height: 100%;
				transition: unset;

				.navigation-link {
					&.active {

						>.nav-anchor,
						>.btn-nav-anchor {
							color: var(--kabaddi-primary-color);
							background-color: transparent;
						}
					}

					&-list {
						height: 100%;
						border: none;
						width: 100%;
						padding: 0;
						overflow: unset;
						@include flex-config(flex, null, flex-end, center);
					}
				}
			}

			&-logo {
				width: 10rem;
				margin-top: 4rem;
				z-index: var(--logo-z);
			}
		}

		.partner-wrap {
			position: relative;
			right: auto;
			margin-left: 1rem;

			.partner-item {
				height: 4.5rem;
			}
		}
	}

	.secondary-site,
	.site {
		&-header {
			max-width: var(--container-max-width);
			margin: 0 auto;

			.submenu {
				.btn-nav-anchor {
					&[aria-expanded="true"] {
						~.sub-ul {
							padding-bottom: 0;
						}
					}
				}
			}
		}

		&-nav {
			.navigation-link {
				&::after {
					display: none;
				}

				.sub-ul {
					background-color: var(--white-color);
					width: 16rem;
					border-bottom: 0.2rem solid var(--kabaddi-accent-color);
					@include box-shadow(0px 3px 6px rgb(var(--black-color-rgb), 0.16));
					@include position(5rem, 0, null, null);

					.nav-link {
						&:not(:last-child) {
							border-bottom: 0.1rem solid var(--light-grey-color);
						}

						&.active,
						&:hover {
							>.nav-anchor {
								background-color: var(--secondary-color);
								color: var(--white-color);
								font-family: $font-bold;
							}
						}
					}

					.nav-anchor {
						color: var(--kabaddi-secondary-color);
					}
				}
			}

			.nav-anchor,
			.btn-nav-anchor {
				padding: var(--half-space) var(--full-space);
				color: var(--light-grey-color);
				font-size: 1.7rem;
			}

			.btn-nav-anchor {
				padding-right: calc(var(--full-space) * 2);

				&:after {
					top: 60%;
				}

				&[aria-expanded="true"]:after {
					top: 50%;
				}
			}
		}
	}

	.secondary-site {
		.site-logo {
			display: block;
		}

		&-header {
			padding: 0;

			&:after {
				display: none;
			}
		}

		&-nav {
			overflow: unset;
			width: auto;

			.navigation-link {
				&-list {
					overflow: unset;
				}

				&.active {

					>.nav-anchor,
					>.btn-nav-anchor {
						color: var(--kabaddi-accent-color);
					}
				}

				.sub-ul {
					position: absolute;
					transform: unset;
					bottom: unset;
				}
			}

			.btn-nav-anchor,
			.nav-anchor {
				color: var(--kabaddi-secondary-color);
			}

			.btn-nav-anchor {
				.btn-text {
					font-size: 1.7rem;
				}
			}
		}
	}

	.secondary-site-header {
		.logo-text {
			display: block;
		}
	}

	.site-header {
		.social-wrap {
			display: none;
		}
	}

	.wpl-page {
		.menu-wrap {
			.site-logo {
				margin-top: 0;
			}
		}

		.logo {
			width: 9rem;
		}

		&.home {
			.logo {
				width: 6rem;
			}
		}
	}

}

@media screen and (min-width: $desktop-min-width) {
	.wpl-page.home .logo {
		width: 12rem;
	}
}