@use "../config" as *;

.footer {
  &-bottom {
    .footer-meta {
      font-family: $font-bold;
      height: var(--footer-bottom-height);
      color: var(--white-color);
      @include flex-config(flex, column, center, center);

      &-wrapper {
        @include linear-gradient(0deg, var(--accent-color) 100%, var(--accent-color) 100%);
      }

      .copyright-text {
        text-align: center;
        font-size: 1.2rem;
        color: var(--kabaddi-secondary-color);
      }

      .poweredby {
        margin-top: 0.75rem;

        .title {
          font-size: 1rem;
        }

        .logo {
          background: url(/static-assets/images/cssimages/si-logo.png?v=#{$image-version}) no-repeat;
          filter: brightness(0) invert(1);
          width: 7rem;
          height: 3rem;
          display: inline-block;
          vertical-align: middle;
          margin-left: var(--half-space);
          background-size: 100% auto;
          font-size: 0;
        }
      }
    }
  }

  &-middle {
    max-width: var(--container-max-width);
    margin: 0 auto;
    height: var(--footer-middle-height);
    @include flex-config(flex, column-reverse, center, center);

    &-section {
      background-color: var(--secondary-color);

      .footer-link {
        padding: 0 var(--half-space);
        position: relative;

        &:not(:last-child) {
          &::after {
            content: "";
            background-color: var(--white-color);
            height: 1.2rem;
            width: 0.1rem;
            position: absolute;
            right: 0;
            transform: translate(-50%, -50%);
            top: 62%;
          }
        }

        .link-text {
          color: var(--white-color);
          font-size: 1.1rem;
          font-family: $font-medium;
        }
      }

      .footer-link-list {
        @include flex-config(flex, null, center, center);
      }
    }
  }

  &-top {
    padding-top: 5rem;
    @include flex-config(flex, null, center, center);

    .principal-partner,
    .associate-partner {
      .client {
        &-title {
          font-size: 1.6rem;
          line-height: 1.4;
          font-family: $font-bold;
          text-align: center;
          text-transform: uppercase;
          margin: 0 auto var(--full-space);
        }

        &-sub-title {
          display: none;
        }

        &-logo {
          width: 27rem;
          height: 9rem;
          text-align: center;
          margin: 0 auto calc(4 * var(--full-space));

          .client-img {
            height: 100%;
            width: auto;
          }

          &.logo {
            &-1 {
              width: 5rem;
              height: 7rem;
            }

            &-2 {
              width: 11rem;
              height: 7rem;
            }

            &-3 {
              width: 15rem;
              height: 7rem;
            }
          }
        }
      }
    }

    .principal-partner {
      .client-logo {
        height: 12rem;
      }
    }
  }
}

.footer-wrap {

  .footer-menu-links,
  .social-wrap {
    width: 100%;
    height: 50%;
    @include flex-config(flex, null, center, center);
  }

  .social-wrap {
    border-bottom: 0.1rem solid rgb(var(--white-color-rgb), 7%);

    .social-link-list {
      .link-item {
        .nav-link {
          color: var(--white-color);
        }
      }
    }
  }
}

.home {
  .footer {
    &-middle-section {
      background-color: var(--secondary-color);
    }

    &-bottom {
      .footer-meta-wrapper {
        @include linear-gradient(0deg, var(--accent-color) 100%, var(--accent-color) 100%);
      }

      .footer-meta {
        .poweredby {
          display: none;
        }
      }
    }
  }
}

.wpl-page{
  .footer{
    &-top{
      .client{
        &-logo{
          .client-img{
            @extend %w-100;
            object-fit: contain;
          }
        }
      }
      .principal-partner,
      .associate-partner {
      .client {
        &-title{
          font: 1.8rem/1.6rem $font-bold;
        }
        &-logo {
          height: 8rem;
          .client-img {
            @extend %w-100;
          }
        }
      }
      }
      .principal-partner {
        .client-logo {
          height: 10rem;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet-min-width) {
  .footer {
    &-bottom {
      .footer-meta {
        max-width: var(--container-max-width);
        margin: 0 auto;
        @include flex-config(null, row, space-between, null);

        .poweredby {
          margin-top: 0;
        }
      }
    }

    &-middle {
      @include flex-config(null, row, space-between, null);
    }

    &-top {
      @include flex-config(null, row, null, null);

      .client-section-wrap {
        width: 100%;
        padding: 0 calc(8 * var(--full-space));
      }

      .associate-partner {
        width: 100%;

        .client-list {
          width: 100%;
          @include flex-config(flex, null, center, center);

          .client-item {
            width: 20%;
            margin-left: var(--full-space);

            .client-logo {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .footer-wrap {

    .footer-menu-links,
    .social-wrap {
      width: auto;
    }

    .social-wrap {
      border-bottom: none;
    }
  }
  .wpl-page{
    .footer{
      &-top{
        .principal-partner,
        .associate-partner {
        .client {
          &-title{
            margin: 0 auto calc(var(--full-space) * 2);
            font: 2rem/1.8rem $font-bold;
          }
        }
        }
      }
    }
  }
  
}