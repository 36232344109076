@use "../config" as *;

.waf-listing {
  padding: 0;

  .layout-wrapper {
    max-width: var(--container-max-width);
    margin: 0 auto;
  }

  .waf-head {
    margin-bottom: var(--half-space);
  }

  &.listing-page {
    .item-type-article {
      .article {
        &-title {
          margin: 0;
        }

        &-wrap {
          @include flex-config(flex, null, null, null);
        }

        &-thumbnail {
          flex-basis: 30%;
          width: 30%;
        }

        &-content {
          flex-basis: 70%;
          width: 70%;
          padding-left: var(--full-space);
        }

        &-meta {
          position: absolute;
          bottom: 0;
        }
      }
    }

    .article {
      &-title {
        margin: var(--half-space) 0;
      }

      &-item {
        width: 100%;
        padding-bottom: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  .article {
    &-title {
      font-size: var(--listing-article-title-size);
      font-family: $font-bold;
      color: var(--kabaddi-secondary-color);
      @include truncate-vertical(2, var(--listing-article-title-height));
    }

    &-thumbnail {
      &::before {
        content: "";
        width: 0;
        height: 0.3rem;
        background: var(--kabaddi-primary-color);
        position: absolute;
        bottom: 0;
        z-index: var(--z-index1);
        @include transition(0.5s all);
      }
    }

    &-item {
      margin-bottom: var(--full-space);
      padding-bottom: var(--full-space);
    }

    &-list {
      flex-wrap: wrap;
      position: relative;
      @include flex-config(flex, null, null, null);
    }
  }

  .first-list,
  .second-list {
    .article-content {
      .item-type-icon {
        display: none;
      }
    }
  }

  .third-list {
    .item-type-icon {
      left: 0;
      bottom: 0;
    }
    .article {
      &-item {
        padding-left: 0;

        &:after {
          content: "";
          background: var(--kabaddi-secondary-color);
          height: 0.3rem;
          display: block;
          width: 100%;
          opacity: 0.1;
          @include border-radius(0.3rem);
          @include position(null, null, 0, 0);
        }
      }

      &-content {
        padding-left: calc(var(--full-space) * 2);
      }
      &-meta {
        .meta {
          &.meta-category {
            display: none;
          }
        }
      }
    }
  }

  &.latest-update {
    background: var(--light-gray-color);
    margin: 0 var(--half-space-negative);
    padding: var(--full-space);

    @include grid-list();

    &.kabaddi,
    &.legend-cricket {
      background: url("/static-assets/images/cssimages/kabaddi-bg.png?v=#{$image-version}") no-repeat;
      background-size: 100% 100%;
    }
  }

  &.kho-kho {
    background-color: var(--tertiary-color);

    .article-item {
      padding: var(--half-space) 0;
    }

    .head-tab li a,
    .waf-head .title,
    .article-title {
      color: var(--white-color);
    }

    .article-item:after,
    .head-tab li:not(:last-child):after {
      background-color: var(--white-color);
    }

    .article-meta .meta {
      color: rgba(var(--white-color-rgb), 0.9);
    }

    .first-list {
      .article-title {
        @include truncate-vertical(2, 4.2rem);
      }
    }
  }

  &.rhs-listing {
    .article {
      @include grid-style();
    }

    .item-type-icon {
      width: 2rem;
      height: 2rem;

      &::after {
        font-size: 1rem;
      }
    }
  }
}

.kabaddi-page,.home,.ultimate-kho-kho-page,.llc-page,.wpl-page {
  .home-photo {
    .article {
      &-list {
        overflow: auto;
        flex-wrap: nowrap;
        margin: 0 var(--half-space-negative);
      }

      &-item {
        flex-basis: calc(80% - var(--full-space));
        width: calc(80% - var(--full-space));
        flex-shrink: 0;
        margin: 0 var(--half-space);
      }
    }
  }

  .home-news {
    background-color: var(--kabaddi-primary-color);

    .third-list {
      .item-type-icon::after {
        color: var(--kabaddi-secondary-color);
      }
    }

    .waf-head {
      .head-tab li {
        &:not(:last-child):after {
          background: var(--kabaddi-secondary-color);
        }

        a {
          color: var(--kabaddi-accent-color);
        }
      }
    }
  }

  .home-video {
    background: url("/static-assets/images/cssimages/kabaddi-bg.png?v=#{$image-version}") no-repeat;
    background-size: 100% 100%;
    margin: 0 var(--half-space-negative);
    padding: var(--full-space);

    .article {
      &-item {
        width: 100%;
      }

      &-list {
        margin: 0;
      }
    }

    .second-list {
      .article {
        @include grid-style();
      }
    }
  }
}

@media screen and (min-width: $tablet-min-width) {
  .waf-listing {
    &.listing-page {
      .item-type-article {
        .article {
          &-title {
            margin: var(--half-space) 0;
          }

          &-thumbnail,
          &-content {
            flex-basis: 100%;
            width: 100%;
          }

          &-wrap {
            @include flex-config(flex, column, null, null);
          }

          &-content {
            padding-left: 0;
          }

          &-meta {
            position: relative;
            bottom: unset;
          }
        }
      }

      @include absolute-video-content(--white-color);
    }

    .article-item {
      @include card-count(4, var(--half-space));
    }

    &.rhs-listing {
      margin: 0;
      &.video-rhs {
        .waf-body {
          overflow-y: auto;
          &::-webkit-scrollbar {
            height: 0.5rem;
            width: 0.5rem;
          }
          &::-webkit-scrollbar-track {
            background: var(--light-grey-color);
            border-radius: 0.5rem;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 1rem;
            background: var(--dark-grey-color);
          }
          &::-webkit-scrollbar-thumb:hover {
            border-radius: 1rem;
            background: var(--kabaddi-primary-color);
          }
        }
        .article-thumbnail {
          flex-basis: 100%;
          width: 100%;
        }
        @include absolute-video-content(--white-color);
      }
    }

    &.latest-update {
      padding: calc(var(--full-space) * 2) 0;

      .waf-body {
        @include flex-config(flex, null, null, null);
      }

      .waf-head {
        margin-bottom: var(--full-space);
      }

      .first-list {
        flex-basis: 30%;
        width: 30%;

        .article-item {
          flex-basis: 100%;
          width: 100%;

          &:after {
            display: none;
          }
        }
      }

      .list-group-wrap {
        flex-basis: 70%;
        width: 70%;

        .article-item {
          @include card-count(2, var(--half-space));
        }
      }
    }

    &.kho-kho {
      // &::before {
      //     background: url(/static-assets/images/cssimages/khokho-web-bg.png?v=1.0) no-repeat;
      //     background-size: 100% 100%;
      // }

      .list-group-wrap {
        flex-basis: 100%;
        width: 100%;

        .article-item {
          @include card-count(4, var(--half-space));
        }
      }

      .third-list {
        .article-item {
          border-top: 0.1rem solid rgba(var(--white-color-rgb), 0.4);
          border-bottom: 0.1rem solid rgba(var(--white-color-rgb), 0.4);

          &::after {
            display: none;
          }
        }
      }

      .second-list {
        .article {
          &-title {
            margin: var(--half-space) 0;
          }

          &-wrap {
            @include flex-config(null, column, null, null);
          }

          &-content {
            padding: 0;
          }

          &-thumbnail,
          &-content {
            flex-basis: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .kabaddi-page,.home,.ultimate-kho-kho-page,.llc-page,.wpl-page {
    .waf-listing {
      padding: calc(var(--full-space) * 2) 0;
    }

    .home-photo {
      .article {
        &-item {
          @include card-count(4, var(--half-space));
        }

        &-list {
          margin: 0;
        }
      }
    }

    .home-video {
      @include absolute-video-content(--white-color);
      .article-list {
        margin: 0 var(--half-space-negative);
      }
      .first-list {
        .article {
          &-item {
            @include card-count(2, var(--full-space));
          }
        }
      }

      .second-list {
        .article {
          &-item {
            @include card-count(4, var(--full-space));
          }

          &-wrap {
            flex-direction: column;
          }

          &-thumbnail {
            flex-basis: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .waf-listing {
    &.listing-page {
      padding: 0;
    }
  }
}
